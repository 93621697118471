@import 'sb';
.date-picker-wrap{
    .MuiFormControl-marginNormal{
        width:150px;
    }
}
.hor-slider-wrap{
    display:-webkit-box;
}
.hor-slider{
    align-items: center; 
    justify-content: flex-start;
    // width:100%;
    // top:-3px;
    position: relative; 
    display:flex;

    flex-direction: column;
    .MuiButton-textSizeSmall{
        display:table-cell;
    }
}

.alram-header{
    height:30px;
    display:flex;
    // flex-direction: column; 
    align-items: center; 
    justify-content: space-between;
    width:100%;
    top:-3px;
    position: relative;
    .alram-left{
        // position: absolute;
        // left:0;
        // top:0;
    }
    .alram-left-empty{
        // position: absolute;
        // left:0;
        // top:0;
        @include sizeSet(64px,36px);
    }
    .alram-center{
        font-weight: bold;
        font-size: 1.3rem;
    }
    .alram-right{
        // position: absolute; 
        // right:0;
        // top:0;

    }
}  