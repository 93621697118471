@import "sb";
.MuiInputBase-input {
    white-space: break-spaces;
    word-break: break-all;
    word-spacing: 1px;
}
.iconfade-enter {
    opacity: 0.01;
}

.iconfade-enter.iconfade-enter-active {
opacity: 1;
transition: opacity 500ms ease-in;
}

.iconfade-leave {
opacity: 1;
}

.iconfade-leave.iconfade-leave-active {
opacity: 0.01;
transition: opacity 300ms ease-in;
}

.arrow-cont{
    background-color:#ff0000;
    @include sizeSet(22px, 30px);
    left:-20px;
    top:0; 
    border:1px solid #000;
    opacity: 0.5;
    opacity:0;
}
.is_app{
    .tree-item{
        .tool-right{
            right:-44px;
            .tool_alarm{
                display:inline-flex;
            }
        }
    }
}
.tree-item{
    .selected{
        .tool-right{
            display:block;
        }
    }
    .tool-right{
        display:none;
        position: absolute;
        right:-12px;

        top:3px;
        .tool_alarm{
            display:none;
        }
    }
    .MuiTreeItem-iconContainer{
            position: absolute;
            top:8px;
    }
    .label2{
        left: 15px; 
        position: relative; 
    }

    // left:5px;
    .MuiTreeItem-label:hover{
        background-color:transparent!important;
    }
    .MuiTreeItem-label:focus{
        background-color:transparent!important;
    }
    .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label{
        background-color:transparent!important;

    }
    .MuiTreeItem-label:hover{
        background-color:transparent!important;

    }
    .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label{
        background-color:transparent!important;

    }
    .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label{
        background-color:transparent!important;

    }
    position: relative;

     .dash{
        @include sizeSet(100%, 24px);
        // background-color:#faeb48;
        background-color:#dfceb3;
        position: absolute;top:0;
        padding-right:10px;
        top:0px;
        left:-5px; 
        // left:20px; 
        @include transition(all 0.6s ease-out);
        @include transform(matrixXScale(0.00001));
        @include opacity(0);
        @include transform_origin(0, 50%);
    }
    .selection-ab-wrap{
        position: absolute;
        left:0;top:0;
    }
    .selection{

        &.off{
            color:rgba(0,0,0,0)!important;
        }
        overflow-wrap: break-word;
        &.empty{
            min-width:72px;
        }
        text-decoration: underline #aaa;
        @include transition(all 0.6s ease-out);
        position: relative;
        color:#ff0000;
        -webkit-text-size-adjust: none;
        color:rgba(0,0,0,0);
        font-size: 1rem;
        line-height:1.24em;
        letter-spacing: 0.1px;
        top:4px;
        left:0;
        @include opacity(0);

        white-space: break-spaces;
        word-break: break-all;
        word-spacing: 1px;


    }
    .MuiTreeItem-iconContainer{
        margin-right:0;
    }
    .MuiTreeItem-label{
        padding-left:0;
    }
}
.label-cont {
    .input-label-title{
        &.off{
            @include opacity(0);
        }
    }
    .MuiInputBase-multiline{
        padding:3px 0 4px;
    }
    .label-child-cont{
        position: relative;

    }
    width: 100%;
    position: relative;
    .tx-bg{
        position: absolute;
        top:1px;
        width:100%;
        @include transform_origin(0, 0);
        .fake-tf{
            &.off{
                 //color:rgba(0,0,0,0)!important;
                //color:rgba(255,0,0,255)
            }
            overflow-wrap: break-word;
            &.empty{
                min-width:72px;
            }
            background-color:rgba(0,0,0,0);

            @include transition(all 0.6s ease-out);
            position: relative;
            color:#ff0000;
            color:rgba(0,0,0,0)!important;
            -webkit-text-size-adjust: none;


            font-size: 1rem;
            line-height:1.24em;
            letter-spacing: 0.1px;
            top:2px;


            word-spacing: 1px;
            word-break: break-all;
            white-space: break-spaces;
        }
    }
    .done{
        &.tx-bg{
            // clip : rect( 0px, 500px, 800px, 0px );
            .fake-tf{
                @include opacity(1);
            }

        }
        & > .dash{
            @include transform(matrixXScale(1));
            @include opacity(1);
        }
        &.selected{
            // & > .dash{
            //     @include transform(matrixXScale(0.00001));
            //     @include opacity(0);
            // }
        }
       
    }
    .selected{
        .selection{
            @include transform(matrixScale(1));
            @include opacity(1);
        }
    }
    
    .input-wrap{
        position: inherit;

        // height:32px;
        display:inline-block; 
        //left:32px;
        //padding-left:32px;
        //@include transform(matrix2dXY(32,0));

        width:100%;

        .input-child-wrap{
            //width:100%;
            //width:inherit;
            width:90%;
            // position: absolute;
            position: relative;
            display: block;
            left:25px;
            margin-right:20px;
            top:2px;
        }
    }
    .done-wrap{
        @include sizeSet(26px,26px);
        // display:inline-block;

        position: absolute;;
        left:-2px;
        top:4px;
        .iwrap{
            @include sizeSet(26px,26px);
        }
    }
}

