@import "sb";
 

.treefade-enter {
    opacity: 0.01;
}

.treefade-enter.treefade-enter-active {
opacity: 1;
transition: all 500ms ease-in;
}

.treefade-leave {
opacity: 1;
}

.treefade-leave.treefade-leave-active {
opacity: 0.01;
transition: all 300ms ease-in;
}
