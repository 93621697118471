@import 'sb';

.intro{
    background-color:#fff;
    position: fixed;
    @include sizeSet(100%,100%);
    z-index: 10;
    display: flex;
    .Bi-cont{
        justify-content: center;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center; 
        width:100%;
    }
  
    .loading{
        position: fixed;
        width:100%;
        bottom:30px;
        width:100%;
        text-align: center;
        color:#666;
        font-size: 0.8rem;
    }
}