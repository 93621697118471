

$mobile:true;
@import 'device';
@import 'layout';

.pa{
  position:absolute;
}
.pr{
  position:relative;
}

$vendors: ('-webkit-', '-moz-', '-ms-', '-o-', '-sand-', '-khtml-', '');
@if $mobile {
  $vendors: ('-webkit-', '');
}
$cache_day:190310_1740;

// $local:true;
$local:true;
$cpdev:false;
// $cpdev:true;
$cdn_path_cpdev:"https://dev.img.netmarble.kr/mobile/game/nanakr/brand/v2/pc/v2_2/build";
$cdn_path_real:"https://sgimage.netmarble.com/mobile/game/nanakr/brand/v2/pc/v2_2/build";
$path_real:$cdn_path_real;
@if($cpdev){
  $path_real:$cdn_path_cpdev; 
}
$at:unquote("@");

//모바일일 경우 해당 함수를 태움. $some-number를 절반으로 줄여 리턴
@function getMobileSize($some-number) {
  @return ($some-number/2);
}
//$opacity : 0~1인 범위를 0~100으로 변경
@function getFilterAlpha($opacity) {
  @return $opacity*100;
}
//matrix3d의 scale
@function matrixScale($scale){
  @return matrix3d($scale,0,0,0,0,$scale,0,0,0,0,1,0,0,0,0,1);
}
//matrix3d의 scale
@function matrixXScaleYScale($scaleX, $scaleY){
  @return matrix3d($scaleX,0,0,0,0,$scaleY,0,0,0,0,1,0,0,0,0,1);
}
//matrix3d의 scale, x위치, y위치
@function matrixXYScale($scale, $x,$y){
  @return matrix3d($scale,0,0,0,0,$scale,0,0,0,0,1,0,$x,$y,0,1);
}
@function matrixXScale($scale){
  @return matrix3d($scale,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1);
}
@function matrixYScale($scale){
  @return matrix3d(1,0,0,0,0,$scale,0,0,0,0,1,0,0,0,0,1);
}
//matrix3d의 x위치, y위치
@function matrix2dXY($x, $y){
  @return matrixXYScale(1, $x, $y);
}
//matrix3d의 scale, x위치, y위치
@function matrixScaleWidthHeight($width, $height){
  @return matrix3d($width,0,0,0,0,$height,0,0,0,0,1,0,0,0,0,1);
}
@function matrixXYScaleRotation($scale, $x,$y,$rot){
  @return matrix3d($scale,$rot,0,0,0,$scale,$rot,0,0,0,1,0,$x,$y,0,1);
}
//속성 이름 앞에 벤더 프리픽스를 삽입하여 출력
//$property : 벤더 프리픽스 뒤에 붙는 속성
//$value : 해당 속성의 값.
//scss
//@include vendor-prefix-value(transition, transform 0.2s linear);
//css
//-webkit-transition:opacity 0.2s linear;
//transition:opacity 0.2s linear;
//...
@mixin vendor-prefix-property($property, $value) {
  @each $vendor in $vendors {
    #{$vendor}#{$property}: #{$value};
  }
}
@mixin vendor-prefix-property2($property, $value, $args) {
  @each $vendor in $vendors {
    #{$vendor}#{$property}: #{$value} ,$args;
  }
}

@mixin font-size($value) {
  font-size:$value;
  line-height:$value;
}

//속성 이름, 속성 값 앞에 벤더 프리픽스를 삽입하여 출력.
//$property : 벤더 프리픽스 뒤에 붙는 속성
//$value : 벤더 프리픽스 뒤에 붙는 값
//scss
//@include vendor-prefix-value(transition, transform 0.2s linear);
//css
//-webkit-transition:-webkit-transform 0.2s linear;
//transition:transform 0.2s linear;
//...
@mixin vendor-prefix-value($property, $value) {
  @each $vendor in $vendors {
    #{$vendor}#{$property}: #{$vendor}#{$value};
  }
}
//속성 이름, 속성 값 앞에 벤더 프리픽스를 삽입하고 뒤에 일반 값을 더하여 출력.
//$property : 벤더 프리픽스 뒤에 붙는 속성
//$value : 벤더 프리픽스 뒤에 붙는 값
//$args : 뒤에 벤더 프리픽스를 안붙인 값을 추가.
//scss
//@include vendor-prefix-value-args(transition, transform 0.2s linear, opacity 0.2s linear);
//css
//-webkit-transition:-webkit-transform 0.2s linear, opacity 0.2s linear;
//transition:transform 0.2s linear, opacity 0.2s linear;
//...
@mixin vendor-prefix-value-args($property, $value, $args) {
  @each $vendor in $vendors {
    #{$vendor}#{$property}: #{$vendor}#{$value}, $args;
  }
}

//keyframes에 대한 벤더 프리픽스 설정, @content가 반복문 내에 입력이 불가능 하여 벤더 프리픽스 모듈에 태우지 못하고 별도로 만듬
//추후 sass 업데이트 되어 @content 관련 문제 수정되면 공통 벤더 프리픽스 모듈 태울 예정.
@mixin animation_keyframes($name) {
  @-webkit-keyframes #{$name} { @content; }
  @keyframes #{$name} { @content; }
  @if not $mobile{
    @-moz-keyframes #{$name} { @content; }
    @-o-keyframes #{$name} { @content; }
    @-ms-keyframes #{$name} { @content; }
  }
  //@each $vendor in $vendors {
  // #{$at}#{$vendor}keyframes #{$name} {@content;}
  //}
}
//animation_keyframes로 정의한 애니메이션 연결, 반복 설정(int:반복 횟수, infinite:무한 반복)
@mixin ani($data...) {
  @include vendor-prefix-property(animation , $data);
}
//animation_keyframes로 정의한 애니메이션 연결, 반복 설정(int:반복 횟수, infinite:무한 반복)
@mixin animation($ani-name, $count) {
  @include vendor-prefix-property(animation-name , $ani-name);
  @include vendor-prefix-property(animation-iteration-count , $count);
}
//animation_keyframes로 정의한 애니메이션 연결, 무한 반복
@mixin animation_loop($ani-name) {
  @include animation($ani-name, infinite);
}
//animation_keyframes로 정의한 애니메이션 연결, count 설정
@mixin animation_loop_count($ani-name, $count) {
  @include animation($ani-name, $count);
}
//animation_keyframes로 정의한 애니메이션 속도 설정, animation과 animation_speed를 분리하여 용량 감소.
@mixin animation_speed($speed) {
  @include vendor-prefix-property(animation-duration , $speed);
}
//animation의 delay(대기시간) 설정.
@mixin animation_delay($sec){
  @include vendor-prefix-property(animation-delay, $sec);
}
//애니메이션의 움직임에 대한 이징값 ease-out, ease-in, linear, cubic, ...
@mixin animation-timing-function($cubic){
  @include vendor-prefix-property(animation-timing-function, $cubic);
}
@mixin animation_oneline($value){
  @include vendor-prefix-property(animation, $value);
}


//애니메이션&인터레션 관련 transition 모듈
//scss
//@include transition(opacity 0.2s linear);
//css
//-webkit-transition:opacity 0.2s linear;
//transition:opacity 0.2s linear;
//...
@mixin transition($data...) {
  @include vendor-prefix-property(transition, $data);
}

@mixin filter($data...) {
  @include vendor-prefix-property(filter, $data);
}

//애니메이션&인터렉션 관련 transition transform 모듈
//scss
//@include transition_transform(0.2s linear)
//css
//-webkit-transition:-webkit-transform 0.2s linear
//transition:transform  0.2s linear
//...
@mixin transition_transform($data...) {
  @include vendor-prefix-value(transition, transform $data);
}
@mixin transition_filter($data...) {
  @include vendor-prefix-value(transition, filter $data);
}

//$delay 를 둔 transition 모듈
//$delay : 해당 시간 이후에 애니메이션 실행.
//$property : 애니메이션을 할 속성 이름 opacity or {opacity, margin-left}
//$cubic : 이징 값.
//$speed : 속도
@mixin transition_delay($delay, $property, $cubic, $speed) {
  @include vendor-prefix-property(transition-delay, $delay);
  @include vendor-prefix-property(transition-property, $property);
  @include vendor-prefix-property(transition-timing-function, $cubic);
  @include vendor-prefix-property(transition-duration, $speed);
}
@mixin transition_delay_property_value($delay, $property, $cubic, $speed) {
  @include vendor-prefix-property(transition-delay, $delay);
  @include vendor-prefix-value(transition-property, $property);
  @include vendor-prefix-property(transition-timing-function, $cubic);
  @include vendor-prefix-property(transition-duration, $speed);
}

//transform 모듈
//scss
//@include transform(rotate(90deg));
//css
//-webkit-transform:rotate(90deg);
//transform:rotate(90deg);
//...
@mixin transform($data) {
  @include vendor-prefix-property(transform, $data);
}

//transform의 Y축 이동 모듈
//scss
//@include translateY(250px)
//css
//-webkit-transform:translateY(250px);
//transform:translateY(250px);
//...
@mixin translateY($some-number) {
  @include transform(translateY($some-number));
}
@mixin translateX($some-number) {
  @include transform(translateX($some-number));
}
//transform 속성을 가지는 객체의 중심값(pivot)
//50% 50%일 경우 객체의 중신이 pivot이 됨
@mixin transform-origin($x,$y) {
  @include vendor-prefix-property(transform-origin, $x $y);
}
//객체의 가로축, 세로축 중앙을 transform의 중심점으로 지정.
@mixin transform-origin-center() {
  @include transform-origin(50%, 50%);
}

//객체의 투명도에 대한 속성 모듈
@mixin opacity($opacity) {
  @include vendor-prefix-property(opacity,$opacity);
  @if not $mobile{
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity="+getFilterAlpha($opacity)+")";
    filter: alpha(opacity=getFilterAlpha($opacity));
  }
}
@mixin blur($blur){
  @include vendor-prefix-property(filter,blur($blur));
  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='#{$blur}');
}

//width, height 값을 입력
@mixin sizeSet($width, $height){
  width:$width;
  height:$height;
}
//width, height 값을 입력
@mixin sizeFull(){
  @include sizeSet(100%,100%);
}
//PC 사이즈에 대한 가로 세로 값을 입력하면 절반(모바일 사이즈)으로 줄여 반환 함.
@mixin sizeSetMobile($width, $height){
  @include sizeSet(getMobileSize($width),getMobileSize($height) )
}

//레이아웃 관련 모듈은 추후 템플릿에 따라 이름 재정의 및 관련 속성에(position, display) 따라 별도 구현이 필요함. 현재는 임시 버전.
//transform을 이용하여 수평 정렬
@mixin translate_center() {
  $value: 50%;
  left: $value;
  @include transform(translate(#{-$value}));
}


@mixin alignLeft(){
  position: absolute;  left:0;  top:0;  bottom:0;
}
@mixin alignRight(){
  position: absolute;  right:0;  top:0;  bottom:0;
}
@mixin sizeFulFill(){
  width:100%;
  height:100%;
}
@mixin posReset() {
  top: 0;  bottom: 0;  left: 0;  right: 0;
}
@mixin posResetAbsolute() {
  position: absolute;
  @include posReset();
}
@mixin absoluteCenter(){
  top:50%;
  bottom:50%;
  left:50%;
  right:50%;
}
@mixin absoluteCenter2(){
  margin:auto;
  @include posResetAbsolute();
}
@mixin marginEmpty(){
  margin:0;padding:0;
}
@mixin fontStyle($size, $color){
  font-size:$size;
  color:$color;
}
.ab{
  position:absolute;
}
//.ab_sol {
//  position:absolute;
//}
//.posZero{
//  @include posReset();
//}
//.absoluteCenter{
//  @include absoluteCenter();
//}
//

@function getURL($url) {
   @if($local){
    @return $url +"?_="+ $cache_day;
  }@else{
    @return $path_real+ $url +"?_="+ $cache_day;
  }
}
@mixin bg_img($url){
  background-image: url(getURL($url));
}
@mixin bg0($url){
  background:url(getURL($url)) 0 0 no-repeat;
}
@mixin bg1($url){
  background-image:url(getURL($url));
}
@mixin bg2($url){
  background-image:url(getURL($url));
  background-repeat: no-repeat;
}

@mixin bg_just($url) {
  background-image: url(getURL($url));
  background-repeat: no-repeat;
}
@mixin bg_just_repeat($url) {
  background-image: url(getURL($url));
  background-repeat: repeat;

}
@mixin bg_size($url, $width, $height){
    @include bg_just($url);
    // 
  // @if($local){
  //   @include bg_just($url);
  // }@else{
  //   @include bg_just($path_real+$url);
  // }
  
  background-size:$width auto;
  @include sizeSet($width, $height);
}


@mixin bg_size_width_height($url, $width, $height){
  @include bg_just($url);
  background-size:$width $height;
  width: $width;
  height: $height;
}
@mixin bg_seq($url, $bg_width, $width, $height){
  @include bg_just($url);
  background-size:$bg_width auto;
  width: $width;
  height: $height;
}

@mixin scrollbarHide(){
  &::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }
}





@mixin buttonReset(){
  outline: none;
  border: none;
  background-color: transparent;
  button:active, button:focus {
    outline: none;
    border: none;
  }
}
@mixin aReset($color){
  &:link {
    color:$color;
    text-decoration: none;
  }
  &:visited {
    color:$color;
    text-decoration: none;
  }
  &:hover {
    color:$color;
    text-decoration: none;
  }
  &:active {
    color:$color;
    text-decoration: none;
  }
}
@mixin j_bg($url){
  background:url(getURL($url)) no-repeat;
}
@mixin j_bg_full($url){
  background-image:url(getURL($url)) 0 0 no-repeat;
}
@mixin j_bg_image($url){
  background-image:url(getURL($url));
}
@mixin section_bg($url, $min_height){
  min-height: $min_height;
  background-image:url(getURL($url));
  margin:0 auto;
  background-repeat: no-repeat;
  background-position: 50% 0;
}
@mixin bg_full_repeat($url){
  background-image:url(getURL($url));
  background-repeat: repeat;



}
@mixin placeholder_fontfamily($family){
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family:$family;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    font-family:$family;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    font-family:$family;
  }
  &:-moz-placeholder { /* Firefox 18- */
    font-family:$family;
  }
}
@mixin placeholder(){
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    @content;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    @content;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    @content;
  }
  &:-moz-placeholder { /* Firefox 18- */
    @content;
  }
}


@mixin placeholder_size_color($size, $color ){
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    @include fontStyle($size, $color);
  }
  &::-moz-placeholder { /* Firefox 19+ */
    @include fontStyle($size, $color);
  }
  &:-ms-input-placeholder { /* IE 10+ */
    @include fontStyle($size, $color);
  }
  &:-moz-placeholder { /* Firefox 18- */
    @include fontStyle($size, $color);
  }
}


@mixin placeholder_size($size){
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size:$size;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    font-size:$size;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    font-size:$size;
  }
  &:-moz-placeholder { /* Firefox 18- */
    font-size:$size;
  }
}

@mixin placeholder_size_left($size, $padding_left){
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-size:$size;
    text-align:left;
    padding-left:$padding_left;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    font-size:$size;
    text-align:left;
    padding-left:$padding_left;

  }
  &:-ms-input-placeholder { /* IE 10+ */
    font-size:$size;
    text-align:left;
    padding-left:$padding_left;

  }
  &:-moz-placeholder { /* Firefox 18- */
    font-size:$size;
    text-align:left;
    padding-left:$padding_left;

  }
}

@mixin placeholder_color($color){
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color:$color;
  }
  &::-moz-placeholder { /* Firefox 19+ */
    color:$color;
  }
  &:-ms-input-placeholder { /* IE 10+ */
    color:$color;
  }
  &:-moz-placeholder { /* Firefox 18- */
    color:$color;
  }
}
@mixin text_size_adjust($param){
  @include vendor-prefix-property(text-size-adjust, $param);
}
@mixin inputClear(){
  outline:none;
  border-style:none;
  background:transparent;
}

@mixin centerTextLeftTop($left, $top, $width, $height){
  left:$left - 50px;
  top:$top;
  @include sizeSet($width+100px, $height);
}
@mixin clearLineHeight(){
  line-height:inherit;
}