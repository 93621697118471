@import 'sb';

.setting-header-wrap{
    position: relative;
    &.privacy_header{
        position: fixed;
        z-index: 10;
        background-color:#fff;
        //border-bottom:1px solid #666;
    }

    height:64px;
    display: block;
    // border-bottom: 1px rgba(0, 0, 0, 0.12) solid;
    line-height:64px;
    vertical-align: middle; 
    width:100%;
    text-align: center;;
    .setting-back{
        position: absolute;
        left:12px;
        top:-3px;
    }
    .setting-close{
        position: absolute;
        right:12px;
        top:-3px;
    }
}