//@import 'base';
@mixin getMobileLeftTop($left, $top){
  top:getMobileSize($top);
  left:getMobileSize($left);
}
@mixin getLeftTop($left, $top){
  top:$top;
  left:$left;
}
@mixin left_top($left, $top){
  top:$top;
  left:$left;
}
@mixin left-top($left, $top){
  top:$top;
  left:$left;
}
@mixin left_bottom($left, $bottom){
  bottom:$bottom;
  left:$left;
}
@mixin right_top($right, $top){
  top:$top;
  right:$right;
}
@mixin right_bottom($right, $bottom){
  bottom:$bottom;
  right:$right;
}
@mixin getAbLeftTopMobile($left, $top){
  position: absolute;
  @include getMobileLeftTop($left,$top);
}
@mixin getAbLeftTop($left, $top){
  position: absolute;
  top:$top;
  left:$left;
}
@mixin getAbLeftBottom($left, $bottom){
  position: absolute;
  bottom:$bottom;
  left:$left;
}
@mixin getAbRightTop($right, $top){
  position: absolute;
  top:$top;
  right:$right;
}
@mixin getAbRightBottom($right, $bottom){
  position: absolute;
  bottom:$bottom;
  right:$right;
}
@mixin getIndexLeft($width, $index){
  left:  getMobileSize($width) * ($index - 1);
}


@mixin getBgImageMobile($bgpath, $width, $height){
  $result_width : getMobileSize($width);
  $result_height: getMobileSize($height);

  width:$result_width;
  height:$result_height;

  background: url($bgpath) no-repeat;
  background-size:$result_width auto;
}
@mixin getBgPos($height, $vertical_count, $index){
  $result_height: getMobileSize($height/$vertical_count);
  background-position: 0px #{-($result_height * $index)};
}
@mixin getFrameBgIndex($width, $height, $count_horizontal,  $count_vertical, $index){
  $result_width: getMobileSize($width/$count_horizontal);
  $result_height: getMobileSize($height/$count_vertical);
  $x: $index % $count_horizontal;
  $y: floor($index / $count_horizontal);
  background-position: #{-($result_width * $x)} #{-($result_height * $y)};
}
@mixin getFrameBg($bgpath, $width, $height, $count_horizontal, $count_vertical){
  width: getMobileSize($width / $count_horizontal)-4px;
  height: getMobileSize($height / $count_vertical)-4px;

  background: url($bgpath) no-repeat;
  background-size:getMobileSize($width) getMobileSize($height);
}
@mixin getBtnIndexStyle($bgpath, $width, $height, $vertical_count){

  $result_height: getMobileSize($height/$vertical_count)  - 4px;
  $result_width : getMobileSize($width);
  @include getBgImage($bgpath, $result_width, $result_height)
}

@mixin getBgImage($bgpath, $result_width, $result_height){
  width:$result_width;
  height:$result_height;

  background: url($bgpath) no-repeat;
  background-size:$result_width auto;
}

@mixin clearULStyle(){
  @include marginEmpty();
  position: absolute;
}
@mixin clearLIStyle(){
  display:block;
  float:left;
  list-style: none;
  @include marginEmpty();
}
@mixin clearListStyle(){
  ul{
    @include clearULStyle();
    li {
      @include clearLIStyle();
    }
  }
}
@mixin getULButton{
  @include clearULStyle();
}
@mixin getLIButton{
  @include clearLIStyle();
}
@mixin getButton($src, $width, $height, $vertical_count){
  //default off
  button{
    @include getButtonInside($src, $width, $height, $vertical_count);
  }
}
@mixin getButtonInside($src, $width, $height, $vertical_count){
  @include getBtnIndexStyle($src, $width, $height, $vertical_count);
  @include getBgPos($height,$vertical_count,1);
  display:none;
  &.on{
    display:block;
    @include getBgPos($height,$vertical_count,0);
  }
  &.off{
    display:block;
    @include getBgPos($height,$vertical_count,1);
  }
  @if($vertical_count==3){
    &.done{
      display:block;
      @include getBgPos($height,$vertical_count,2);
    }
  }
}

@mixin getSingleButton($src, $width, $height, $left, $top){
  position: absolute;
  background:url($src) no-repeat;
  background-size:getMobileSize($width) auto;
  @include sizeSetMobile($width, $height);
  @include getMobileLeftTop($left, $top);
}
@mixin getSingleCenterButton($src, $width, $height, $left, $top){
  position: relative;
  background:url($src) no-repeat;
  background-size:getMobileSize($width) auto;
  @include sizeSetMobile($width, $height);
  margin:0 auto;
}

@mixin fontstyle($size, $color, $weight){
  font-size:getMobileSize($size);
  color:$color;
  font-weight: $weight;
}

@mixin centerText($position, $top, $size, $color, $weight){
  position: $position;
  text-align: center;
  width:100%;
  top:getMobileSize($top);
  font-size:getMobileSize($size);
  color:$color;
  font-weight: $weight;
}
@mixin abText($left, $top, $size, $color, $weight, $width, $height){
  position: absolute;
  text-align: center;
  top:getMobileSize($top);
  left:getMobileSize($left);
  font-size:getMobileSize($size);
  color:$color;
  font-weight: $weight;
  @include sizeSetMobile($width, $height);
}
@mixin removeATextDecoration(){
  &:link {text-decoration: none; }
  &:visited {text-decoration: none;}
  &:active {text-decoration: none; }
  &:hover{text-decoration: none;}
}
@mixin removeAStyle($color){
  &:link {text-decoration: none; color: $color;}
  &:visited {text-decoration: none; color: $color;}
  &:active {text-decoration: none; color: $color;}
  &:hover{text-decoration: none; color: $color;}
}


@mixin ellipse_1($width){
  max-width:$width;
  //padding:0 5px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
@mixin ellipse_multi($height, $line){
  display: -webkit-box;
  display: -ms-flexbox;
  display: box;
  max-height: $height;
  overflow: hidden;
  vertical-align: top;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line
}
@mixin ellipse_multi_v2(){
  /* hide text if it more than N lines  */
  overflow: hidden;
  /* for set '...' in absolute position */
  position: relative;
  /* use this value to count block height */
  line-height: 1.2em;
  /* max-height = line-height (1.2) * lines max number (3) */
  max-height: 2.4em;
  /* fix problem when last visible word doesn't adjoin right side  */
  text-align: justify;

  /* */
  margin-right: -1em;
  padding-right: 1em;
  &:before {
    /* points in the end */
    content: '...';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of block */
    right: 0;
    bottom: 0;
  }
  &:after {
    /* points in the end */
    content: '';
    /* absolute position */
    position: absolute;
    /* set position to right bottom corner of text */
    right: 0;
    width: 1em;
    /* set width and height */
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
}
#site_description{
  display:none
}
