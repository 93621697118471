.form-cont{
    position: relative;
    left:-9px;
    &.board-type{ 
        margin-top:6px;
        .tf-cont{
            position: relative;
            left:9px;
        }
    }
    &.open-type{
        margin-top:3px;
        .tf-cont{
            position: relative;
            left:9px;
        }
    }
}