@import 'sb';
@import './LoginFirebaseUI';
.login-wrap{
    background-color:#fff;
    position: fixed;
    @include sizeSet(100%,100%);
    z-index: 10;
    display: flex;
    // @include opacity(0);



    .bg{
        background-color:#fff;
        position: absolute;
        top:0;
        @include sizeSet(100%,100%);


    } 
    .login-contents{
        .apple-login{
            position: relative;
            display:block;
            @include bg_size("/assets/login/appleid_button@2x.png", 210px, 40px );
            box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
            // top:51px;
            cursor:pointer;
        }
        //ul{
        //    li{
        //        list-style: none;
        //        @include sizeSet(210px, 40px);
        //    }
        //}
        .firebaseui-idp-button{
            width:210px;
        }
        @include transition(all linear 0.4s);
        @include opacity(0);

        justify-content: center;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-align-items: center;
        align-items: center; 
        width:100%;
        // height:500px;
        top:-10px;
        position: relative;;
        flex-direction: column;
        .firebaseui-auth-container{
            margin-top:50px;;
        }
    }
    .bi-cont-full-wrap{
        // @include transition(all linear 0.4s);
        @include opacity(1);

    }
    &.on{
        .login-contents{
            @include opacity(1);
        }
        .bi-cont-full-wrap{
            @include opacity(0);
        }
    }
    .agree-text{
        margin-top:50px;
        color:#000;
        font-size:0.7rem;
        ;z-index: 1;
        position: relative;
        text-align: center;
        .link{
            color:#0000cc;
            font-weight: bold;
            cursor:pointer; 
        }
    }

    .copyright{
        position: absolute;
    bottom: 30px;
    width:100%;
    text-align: center; 
    font-size:0.7rem;

    }
}