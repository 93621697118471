@import 'sb';
.toolheader-cont{
    .icon-category-wrap{
        @include sizeSet(44px, 44px);
        display:inline-flex;
        align-items: center;
        justify-content: center;
    }
    .icon-category{
        @include sizeSet(26px, 26px);
        display:inline-flex;
    }
    .tf-wrap{
        top:-5px;
        // left:25px;
        position: relative;
    }
  
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: #fff;
    border-bottom:1px #eee solid;
    height:44px;
    width:100%;
    .icon-cont{
        &.header_right{
            //margin-right:10px;
        }
        top:0;
        display:flex;
        flex-direction: row;
        justify-content: space-around;
        position: absolute;
        // container size
        //@include sizeSet(44px, 44px);
        @include sizeSet(88px, 44px);
        right:0;
     


    }
    .icon-cont-bg{
        background-image:url("/assets/cover.png");
        width:300px;height:44px;
        right:-160px;
        background-size:300px 44px;
        position: absolute;
    }
    .item{
        // flex-grow:1;
        // width:30px;height:36px;
        @include sizeSet(44px,44px);
        // display: inline-flex;

    }
    .icon-wrap{
        display:inline-flex;
        justify-content: center;
        .icon-ct{
            @include sizeSet(26px, 26px);
        }
     
        &.rt{
            display:flex;
            align-items: center;
        }
    }
}