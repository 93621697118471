.tree-ui-cont{
    position: relative;
    // margin-bottom:10px;
    margin-top:99px;
    margin-right: 34px;
    margin-left:2px;

}

.is_app {
    .tree-ui-cont {
        margin-right: 58px;

    }
}
.icon-header-wrap{
    position: relative;
    height:40px;

}
.icon-header{
    margin:16px;
    &.right{
        position: absolute;
        right:0;
    }
    &.left{
        position: absolute;
    }
}