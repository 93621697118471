@import 'sb';
.tool-editor{
    background-color: #fff;
    border-bottom:1px #eee solid;
     border-top:1px #eee solid;
    height:44px;
    top:44px;
    position: fixed;
    //position:sticky;

     //bottom:0;;
    width:100%;
    .tool-cont{
        display:flex;
        width: 100%;
        height: 30px;
        position: relative;
        top:8px;
        justify-content: space-around;

        .icon-wrap{
            // flex-grow: 1;;
        }
    }
    .icon-wrap{
        @include opacity(0.2);
        &.on{
        @include opacity(1);

        }
    }
}