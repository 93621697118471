@import 'sb';
.icon-wrap-right{
    position: absolute;
    right:16px;
}


.list-header{
    @include sizeSet(100%, 44px);
    top:0;
    display:flex;
    justify-content: space-between!important;
    flex-direction: row;
    padding-left:0;
    padding-right:0;
} 
.list-wrap{
    .MuiList-padding{
        padding-top:0;
        padding-bottom:0;
    }
    .header-icon-wrap{
        @include sizeSet(44px, 44px);
        justify-content: center;
        display:flex;
        // display:inline-flex;
        align-items: center;
        justify-content: center;
        .right{
            position: absolute;
            right:14px
        } 
        .header-icon-child{
            @include sizeSet(26px,26px);
        }
    }
    .list-item{
        &.done{
            .lnb-list-done{
                border-top:solid #fff2c4 1px;
                // background-color:#000;
                //background-color:rgb(255, 224, 71);
                //background-color:#8be668;
                background-color:#ffc600;
                @include opacity(0.15);
                //@include opacity(1);
            }
            .lnb-list-title{
                color:#000;
                @include opacity(0.5);

                position: relative;
            }
        }
        .lnb-list-done{
            position: absolute;
            @include sizeSet(100%, 100%);
            top:0;left:0;
            @include opacity(0);
            @include transition(all linear 0.4s);
        }
        .bg{
            @include sizeSet(80%,100%);
            @include opacity(0);
            position: absolute;
            left:0;top:0;
        }
        &.MuiListItem-gutters{
            padding-top:0;
            padding-bottom:0;
        }
        &.MuiList-padding{ 
            padding-top:0;
            padding-bottom:0;
        }
        display:flex;
        justify-content: space-between!important;
        flex-direction: row;
        .done-wrap{
            left:3px;
            position: relative;;
        }
    }
}