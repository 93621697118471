@import 'sb';
.share-btn-root{
    position: relative;
    @include sizeSet(26px, 26px);

    .MuiSpeedDial-fab{
        display:none;
    }
    .makeStyles-speedDial-67.MuiSpeedDial-directionDown, .makeStyles-speedDial-67.MuiSpeedDial-directionRight{
        left:0;
        top:0;
        @include left_top(-12px,25px);
    }
}
.dim{
    position: fixed;
    @include sizeSet(100%,100%);
    @include left_top(0,0);
}
.radio-wrap{
    // @include left_top(20px,10px);
    position: relative;
    left:20px;
    margin-top:7px;
}