@import 'sb';

.skin-list{
    // input {
    //     -webkit-appearance: none;
    //        -moz-appearance: none;
    //             appearance: none;
    // }
    
    // /* IE10 이상에서 input box 에 추가된 지우기 버튼 제거 */
    // input::-ms-clear { display: none; }
    
    // /* input type number 에서 화살표 제거 */
    // input[type=number]::-webkit-inner-spin-button,
    // input[type=number]::-webkit-outer-spin-button {
    //     -webkit-appearance: none;
    //        -moz-appearance: none;
    //             appearance: none;
    // }
    // display: flex;
    .line-wrap{
        display: flex;
    }
    .MuiButtonBase-root{
        background-color:#fff;
        min-height:25px;

    }
    .color-pal{
        display:inline-block;
        // @include sizeSet(25px, 30px);

    }
    .color-result{
        display:inline-block;

        // @include sizeSet(50px, 30px); 
        width:60px;
        margin-left:10px;
        text-align:center;
        display:none;
    }
  
}
.wrap-color{
    display: block;
 
    .tf{
        margin-left:10px;
        display:inline-block;
        line-height: 27px;
        vertical-align: middle;;
       
    }
    .wrap-c-1{ 
        // width:80px;
        // display: inline-block;;
        // text-align: right;;
        // margin-right:10px;

    }
    .wrap-c-2{
        // width:80px;
        // display: inline-block;;
        // text-align: right;;
        // margin-right:10px;

    }
    .wrap-c-3{
        // margin-left:30px;
        // width:80px;
        // display: inline-block;;
        // text-align: right;;
        // margin-right:10px;

    }
}  