@import 'sb';
.bi-cont-full-wrap{
    @include sizeSet(100%, 100%);
    position: absolute;
    .bi-cont{
        height:100%;
    }

}
.bi-cont{
    justify-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center; 
    width:100%;
}
.bi{
    .bicheck{
        @include sizeSet(20px, 20px);
        top:2px;
    }
    .title{
        display: block;
        position: relative;
        @include sizeSet(127px, 24px);
        margin:0 auto;
        // @include transform(scale(1.2))
        left:-8px;
      

    }
    .icon-cont{
        svg{
            color:rgba(0, 0, 0, 0.54);
        }
    }
    .tx_cont{
        left:22px;
        display:initial;
        .bg{
            background-color:rgb(255, 224, 71);
            position: absolute;
            @include sizeSet(100%, 24px);
            top:0;
            // @include opacity(0.6);
        }
        .tx{
            position: relative;
            display: inline-block;
            font-size:1rem;
            padding-left:6px;
            padding-right:6px;
            font-family: 'Nanum Gothic', sans-serif;
            line-height: 24px;
            vertical-align: middle;
        }
    }
}
    
  