.privacy{
    position: absolute;
    top:0;
    z-index:10;
    .bg{
        position: absolute;
        background-color: #fff;
        width: 100%;
        height: 100%;
    }
    .contents{
        position: relative;
        padding-left:20px;
        padding-right:20px;
        top:70px;
    }
}